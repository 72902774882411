import { Layout, Row } from "antd";
import React, { useEffect, useState, useRef } from "react";
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { useBoolean } from "usehooks-ts";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { useDashboardPageStyles } from "./styles";
import { statisticsService } from "../../../services/StatisticsService";

const { Content } = Layout;

/**
 * The dashboard (usage report) page
 */
const DashboardPage = () => {
  const { mainContentCls } = useDashboardPageStyles();
  const [selectedOption, setSelectedOption] = useState("Vanoord Final");

  const handleToggle = (option) => {
    setSelectedOption(option);
  };

  // MongoDB charts embedding
  const dashboardDiv = useRef(null);
  const dashboardDiv2 = useRef(null);
  useEffect(async() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-vanoord-btabugz',
    });
    // embed a dashboard
    const dashboard = sdk.createDashboard({
      dashboardId: '24d94030-f041-4cac-8090-313736088adc',
    });
    const dashboard2 = sdk.createDashboard({
      dashboardId: '11c94ef9-1077-4a81-9f3b-5789aa0a33db',
    });
    async function renderDashboard() {
        try {
            await dashboard.render(dashboardDiv.current);
        } catch (err) {
            console.error('Error rendering dashboard 1:', err);
        }
        try {
            await dashboard2.render(dashboardDiv2.current);
        } catch (err) {
            console.error('Error rendering dashboard 2:', err);
        }
    }
    renderDashboard();
  }, [selectedOption]);

  return (
    <Layout style={{ minHeight: "100vh", maxHeight: "3420px" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          {/* <Row align="middle" justify="end">
            <Button type="primary" onClick={showModal}>
              Generate report
            </Button>
          </Row> */}
          <Row>
          <div style={{ textAlign: "center", marginTop: "-25px" }}>
            <span
              onClick={() => handleToggle("Vanoord Final")}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
                color: selectedOption === "Vanoord Final" ? "blue" : "black",
              }}
            >
              Vanoord Dashboard 1
            </span>
            <span
              onClick={() => handleToggle("Vanoord Final 2")}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
                color: selectedOption === "Vanoord Final 2" ? "blue" : "black",
              }}
            >
              Vanoord Dashboard 2
            </span>
          </div>
         {
          selectedOption === "Vanoord Final" ?
          <div ref={dashboardDiv} style={{width: '100%', height: 'calc(80vh)', overflow: 'hidden'}} /> :
          <div ref={dashboardDiv2} style={{width: '100%', height: 'calc(80vh)', overflow: 'hidden'}} />
         }
         
           <div style={{
                      position: "fixed",  // Change to fixed to stay at the bottom
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "22px",  // Adjust this height to cover the footer
                      backgroundColor: "#f0f2f5",
                      zIndex: 1  // Ensure it stays above the iframe content
                    }}>
          </div>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
