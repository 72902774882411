import { gql } from "graphql-request";
import { getGraphQLClientInstance } from "../clients/clientFactory";

/**
 * UsersService graphql endpoints
 */
export class UsersService {
  /**
   * To call the get user information endpoint
   */
  async getUser(id: string) {
    const query = gql`
      query ($id: String!) {
        userById(id: $id) {
          firstName
          lastName
          username
          email
          userRoles
        }
      }
    `;

    const { userById } = await getGraphQLClientInstance({ secured: true }).request(query, {
      id: id
    });

    return userById;
  }

  /**
   * To call the get user client roles endpoint
   */
  async getUserClientRoleMappings(id: string) {
    const query = gql`
      query ($id: String!) {
        userRoleMappingsByUserId(userId: $id, clientRole: true) {
          roleId
          roleInfo {
            name
            client
            clientInfo {
              clientId
            }
          }
        }
      }
    `;

    const { userRoleMappingsByUserId } = await getGraphQLClientInstance({ secured: true }).request(query, {
      id: id
    });

    return userRoleMappingsByUserId;
  }

  /**
   * To call the search users endpoint
   */
  async searchUsers(q: string) {
    const query = gql`
      query ($q: String!) {
        usersByEmailOrUsername(q: $q) {
          id
          username
          email
          firstName
          lastName
          emailVerified
          availableApps
          userRoles
        }
      }
    `;

    const { usersByEmailOrUsername } = await getGraphQLClientInstance({ secured: true }).request(query, {
      q: q
    });

    return usersByEmailOrUsername;
  }

  /**
   * To call the create user endpoint
   */
  async createUser({
    username,
    firstName,
    lastName,
    email,
    password,
    realmRoles,
    clientIDs,
    clientRolesID,
    clientRolesName
  }: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    realmRoles: string[];
    clientIDs: string[];
    clientRolesID: string[];
    clientRolesName: string[];
  }) {
    const mutation = gql`
      mutation (
        $username: String!
        $firstName: String!
        $lastName: String!
        $email: String!
        $password: String!
        $realmRoles: [String!]!
        $clientIDs: [String!]!
        $clientRolesID: [String!]!
        $clientRolesName: [String!]!
      ) {
        createUser(
          createUserInput: {
            username: $username
            firstName: $firstName
            lastName: $lastName
            email: $email
            password: $password
            realmRoles: $realmRoles
            clientIDs: $clientIDs
            clientRolesID: $clientRolesID
            clientRolesName: $clientRolesName
          }
        ) {
          id
          username
          firstName
          lastName
          email
        }
      }
    `;

    const { createUser } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      username,
      firstName,
      lastName,
      email,
      password,
      realmRoles,
      clientIDs,
      clientRolesID,
      clientRolesName
    });

    return createUser;
  }

  /**
   * To call the delete user endpoint
   */
  async deleteUser(id: string) {
    const mutation = gql`
      mutation ($id: String!) {
        deleteUser(id: $id)
      }
    `;

    const { deleteUser } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      id: id
    });

    return deleteUser;
  }

  /**
   * To call the update user information endpoint
   */
  async updateUser({ id, firstName, lastName }: { id: string; firstName: string; lastName: string }) {
    const mutation = gql`
      mutation ($id: String!, $firstName: String!, $lastName: String!) {
        updateUser(updateUserInput: { id: $id, firstName: $firstName, lastName: $lastName })
      }
    `;

    const { updateUser } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      id: id,
      firstName: firstName,
      lastName: lastName
    });

    return updateUser;
  }

  /**
   * To call the update user profile photo endpoint
   */
  async updateAvatar({ file, userid }: { file: File; userid: string }) {
    const mutation = gql`
      mutation ($file: Upload!, $userid: String!) {
        updateAvatar(file: $file, userid: $userid)
      }
    `;

    const { updateAvatar } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      file: file,
      userid: userid
    });

    return updateAvatar;
  }

  /**
   * To call the delete user profile photo endpoint
   */
   async deleteAvatar(userid: string, file: string ) {
    const mutation = gql`
    mutation ($file: String!, $userid: String!) { deleteAvatar(file: $file, userid: $userid) }`;

    const { deleteAvatar } = await getGraphQLClientInstance({ secured: true }).request(mutation, {
      file: file,
      userid: userid
    });

    return deleteAvatar;
  }
}

export const usersService = new UsersService();
