import { Button, Form, Input, message, Modal, notification, Select, Space } from "antd";
import { ClientError } from "graphql-request";
import React from "react";
import { LexxApplication } from "../../../consts/LexxApplication";
import { RealmRole } from "../../../consts/RealmRole";
import { useUsers } from "../../../hooks/UserHooks";
import { useClientWithRoles } from "../../../hooks/ClientHooks";
import { XAdminRole } from "../../../consts/XAdminRole";
import { XTeamRole } from "../../../consts/XTeamRole";

const { Option } = Select;

/**
 * The add new user pop up form
 */
const AddNewMemberModal = ({ visible, onCancel }) => {
  const { createUser, searchUsers } = useUsers();
  const { data: xteamClientWithRoles } = useClientWithRoles(LexxApplication.VOUSER);
  const { data: xadminClientWithRoles } = useClientWithRoles(LexxApplication.VOADMIN);

  // Find client role id using role name
  const findRolesID = (clientWithRoles, clientRole) => {
    for (let i = 0; i < clientWithRoles.keycloakRoles.length; i++) {
      if (clientWithRoles.keycloakRoles[i]["name"] == clientRole) {
        return clientWithRoles.keycloakRoles[i]["id"];
      }
    }
    return "";
  };

  // On form submit button clicked
  const onFinish = ({ username, firstName, lastName, email, password, lexxApplication }) => {
    let availableApps;
    let clientIDs;
    let clientRolesID;
    let clientRolesName;

    // To retrieve the realm roles and the client roles
    switch (lexxApplication) {
      // Get the client roles information that is required when creating a user
      case LexxApplication.VOUSER:
        // Get Realm role
        availableApps = [RealmRole.VOUSER];
        // Get client id
        clientIDs = [xteamClientWithRoles.id];
        // Get client role id
        clientRolesID = [findRolesID(xteamClientWithRoles, XTeamRole.user)];
        // Get client role name
        clientRolesName = [XTeamRole.user];
        break;
      // case LexxApplication.VOADMIN:
      //   // Get Realm role
      //   availableApps = [RealmRole.VOADMIN];
      //   // Get client id
      //   clientIDs = [xadminClientWithRoles.id];
      //   // Get client role id
      //   clientRolesID = [findRolesID(xadminClientWithRoles, XAdminRole.ADMIN)];
      //   // Get client role name
      //   clientRolesName = [XAdminRole.ADMIN];
      //   break;
      case LexxApplication.ALL:
      case LexxApplication.VOADMIN:
        // Get Realm role
        availableApps = [RealmRole.VOUSER];
        // Get client id
        clientIDs = [xteamClientWithRoles.id, xadminClientWithRoles.id];
        // Get client role id
        clientRolesID = [];
        clientRolesID.push(findRolesID(xteamClientWithRoles, XTeamRole.user));
        clientRolesID.push(findRolesID(xadminClientWithRoles, XAdminRole.ADMIN));
        clientRolesID.push(findRolesID(xteamClientWithRoles, XTeamRole.author));
        clientRolesID.push(findRolesID(xteamClientWithRoles, XTeamRole.reviewer));
        // Get client role name
        clientRolesName = [XTeamRole.user, XAdminRole.ADMIN, XTeamRole.author, XTeamRole.reviewer];
        break;
      default:
        clientIDs = [];
        clientRolesID = [];
        clientRolesName = [];
    }

    // Call the backend to create a user
    createUser({
      username,
      firstName,
      lastName,
      email,
      password,
      availableApps,
      clientIDs,
      clientRolesID,
      clientRolesName
    })
      .then(() => {
        notification.success({
          message: "Created new user successfully!",
          placement: "topRight"
        });
        onCancel();
        searchUsers("");
      })
      .catch((e) => {
        if (e instanceof ClientError) {
          e.response.errors.forEach((error) => {
            message.error(error.message).then();
          });
        }
      });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Modal title="Add a new user" visible={visible} footer={null} closable={false} onCancel={onCancel}>
      <Form layout="vertical" name="basic" onFinish={onFinish}>
        <Form.Item label="Username" name="username" rules={[{ required: true, message: "Please input username!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input first name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please input last name!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{
              type: 'email',
              message: 'Please input a valid email address!',
            },{ required: true, message: "Please input email address!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" 
        rules={[
          { required: true, message: "Please input password!" },
          {
            pattern: /\d/,
            message: "✕ Must have at least one number"
          },
          {
            pattern: /.*[A-Z].*/,
            message: "✕ Must have at least one capital letter"
          },
          () => ({
            validator(_, value) {
              if (!value || value.length >= 8) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("✕ Must have at least 8 characters"));
            }
          })
        ]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Lexx Application"
          name="lexxApplication"
          rules={[{ required: true, message: "Please select lexx application" }]}
        >
          <Select>
            <Option value={LexxApplication.VOADMIN}>VANOORD-ADMIN-DEFAULT-ROLES</Option>
            <Option value={LexxApplication.VOUSER}>VANOORD-USER-DEFAULT-ROLES</Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Space>
            <Button type="default" onClick={onCancel}>
              Close
            </Button>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewMemberModal;
