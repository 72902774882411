import { Select, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { LexxApplication } from "../../../consts/LexxApplication";
import { useClientWithRoles } from "../../../hooks/ClientHooks";
import { useUserAppsWithClientRoles } from "../../../hooks/UserHooks";

const { Column } = Table;
const { Option } = Select;

/**
 * The role management table
 */
const RoleManagementTable = ({ userId }) => {
  const { data, loading, bindRole, removeRole } = useUserAppsWithClientRoles(userId);
  const { data: xteamClientWithRoles } = useClientWithRoles(LexxApplication.VOUSER);
  const { data: xadminClientWithRoles } = useClientWithRoles(LexxApplication.VOADMIN);
  const [valueChanged, setValueChanged] = useState(false);

  useEffect(()=>{
    setValueChanged(false);
  },[valueChanged]);

  return (
    <>
      <Table dataSource={data} rowKey="name" loading={loading}>
        <Column title="Application" dataIndex="name" />
        <Column
          width="70%"
          title="Roles"
          dataIndex="roles"
          render={(roles, { name: appName }) => {
            return (
              <>
                {roles.map(({ roleId, roleInfo: { name: roleName } }) => {
                  const isLongTag = roleName.length > 20;
                  const tagElem = (
                    <Tag
                      className="edit-tag"
                      key={roleName}
                      closable
                      // closable={xteamClientWithRoles?.['keycloakRoles'][0]?.id !== roleId}
                      onClose={async () => {
                        await removeRole(
                          userId,
                          appName,
                          appName === LexxApplication.VOUSER ? xteamClientWithRoles.id : xadminClientWithRoles.id,
                          {
                            id: roleId,
                            name: roleName
                          }
                        );
                      }}
                    >
                      {isLongTag ? `${roleName.slice(0, 20)}...` : roleName}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={roleName} key={roleName}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}
                {/* {xteamClientWit hRoles?.['keycloakRoles'][0]?.id !== roles[0]?.roleId && !valueChanged &&  */}
                <Select
                  size="small"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Click here to select"
                  optionFilterProp="children"
                  // disabled={roles.length >= 1}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={async (id, { children: name }) => {
                    await bindRole(
                      userId,
                      appName === LexxApplication.VOUSER ? xteamClientWithRoles.id : xadminClientWithRoles.id,
                      appName,
                      {
                        id,
                        name
                      }
                    );
                  setValueChanged(true);
                  }}
                >
                  {appName === LexxApplication.VOUSER && xteamClientWithRoles && (
                    <>
                      {xteamClientWithRoles["keycloakRoles"].map(({ id, name }) => {
                        return (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        );
                      })}
                    </>
                  )}
                  {appName === LexxApplication.VOADMIN && xadminClientWithRoles && (
                    <>
                      {xadminClientWithRoles["keycloakRoles"].map(({ id, name }) => {
                        return (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </Select>
              </>
            );
          }}
        />
      </Table>
    </>
  );
};

export default RoleManagementTable;
