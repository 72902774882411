import { gql } from "graphql-request";
import { getGraphQLClientInstance } from "../clients/clientFactory";

/**
 * StatisticsService graplql endpoints
 */
export class StatisticsService {
  /**
   * To call the get top users endpoint
   */
  async getTopUsers(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        topUsers(dateRange: $dateRange) {
          username
          numberOfQueries
          percentage
        }
      }
    `;

    const { topUsers } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return topUsers;
  }

  /**
   * To call the get top Equipments used
   */
   async getTopEquipments(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        topEquipments(dateRange: $dateRange) {
          equipmentName
          numberOfEquipments
        }
      }
    `;

    const { topEquipments } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return topEquipments;
  }

  /**
   * To call the get top Searched Documents
   */
   async getTopDocumentTypes(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        topDocumentTypes(dateRange: $dateRange) {
          documentTypeName
          numberOfTypes
        }
      }
    `;

    const { topDocumentTypes } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return topDocumentTypes;
  }

  /**
   * To call the get daily-monthly queries endpoint
   */
  async getMonthlyQueries(start = "", end = "") {
    const query = gql`
    query ($dateRange: DateRange!) { monthlyQueries(dateRange: $dateRange) {   month year mmYYYY numberOfQueries day} } 
    `;

    const { monthlyQueries } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return monthlyQueries;
  }

  /**
   *
   * To call the get monthly queries by username endpoint
   */
  async getMonthlyQueriesByUsername(username: string, startDate: string, endDate: string) {
    const query = gql`
      query ($username: String!, $dateRange: DateRange!) {
        monthlyQueriesByUsername(username: $username, dateRange: $dateRange) {
          month
          year
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { monthlyQueriesByUsername } = await getGraphQLClientInstance({ secured: true }).request(query, {
      username: username,
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return monthlyQueriesByUsername;
  }

  /**
   * To call the get queries breakdown endpoint
   */
  async getQueriesBreakdown(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        queriesBreakdown(dateRange: $dateRange) {
          name
          value
        }
      }
    `;

    const { queriesBreakdown } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return queriesBreakdown;
  }

  /**
   * To call the get top fault codes endpoint
   */
  async getTopFaultCodes(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        topFaultCodes(dateRange: $dateRange) {
          code
          numberOfTimes
          percentage
        }
      }
    `;

    const { topFaultCodes } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return topFaultCodes;
  }

  /**
   * To call the get monthly queries by fault code endpoint
   */
  async getMonthlyQueriesByFaultCode(faultCode: number, start: string, end: string) {
    const query = gql`
      query ($faultCode: Float!, $dateRange: DateRange!) {
        monthlyQueriesByFaultCode(faultCode: $faultCode, dateRange: $dateRange) {
          month
          year
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { monthlyQueriesByFaultCode } = await getGraphQLClientInstance({ secured: true }).request(query, {
      faultCode: faultCode,
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return monthlyQueriesByFaultCode;
  }

  /**
   * To call the get top NL searches endpoint
   */
  async getTopNlSearches(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        topNlSearches(dateRange: $dateRange) {
          nlSearch
          numberOfTimes
          percentage
        }
      }
    `;

    const { topNlSearches } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return topNlSearches;
  }

  /**
   * To call the get monthly queries by NL search endpoint
   */
  async getMonthlyQueriesByNlSearch(nlSearch: string, startDate: string, endDate: string) {
    const query = gql`
      query ($nlSearch: String!, $dateRange: DateRange!) {
        monthlyQueriesByNlSearch(nlSearch: $nlSearch, dateRange: $dateRange) {
          month
          year
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { monthlyQueriesByNlSearch } = await getGraphQLClientInstance({ secured: true }).request(query, {
      nlSearch: nlSearch,
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return monthlyQueriesByNlSearch;
  }

  /**
   * To call the get number of NL queries by month endpoint
   */
  async getNumberOfNLQueriesByMonth(startDate: string, endDate: string) {
    const query = gql`
      query ($dateRange: DateRange!) {
        numberOfNLQueriesByMonth(dateRange: $dateRange) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfNLQueriesByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return numberOfNLQueriesByMonth;
  }

  /**
   * To call the get number of fault code queries by month endpoint
   */
  async getNumberOfFaultCodeQueriesByMonth(startDate: string, endDate: string) {
    const query = gql`
      query ($dateRange: DateRange!) {
        numberOfFaultCodeQueriesByMonth(dateRange: $dateRange) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfFaultCodeQueriesByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return numberOfFaultCodeQueriesByMonth;
  }

  /**
   * To call the get information lookups breakdown endpoint
   */
  async getInformationLookupsBreakdown(start = "", end = "") {
    const query = gql`
      query ($dateRange: DateRange!) {
        informationLookupsBreakdown(dateRange: $dateRange) {
          name
          value
        }
      }
    `;

    const { informationLookupsBreakdown } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      }
    });

    return informationLookupsBreakdown;
  }

  /**
   * To call the get number of procedures by month endpoint
   */
  async getNumberOfProceduresByMonth(startDate: string, endDate: string) {
    const query = gql`
      query ($dateRange: DateRange!) {
        numberOfProceduresByMonth(dateRange: $dateRange) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfProceduresByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return numberOfProceduresByMonth;
  }

  /**
   * To call the get number of parts breakdown by month endpoint
   */
  async getNumberOfPartsBreakdownByMonth(startDate: string, endDate: string) {
    const query = gql`
      query ($dateRange: DateRange!) {
        numberOfPartsBreakdownByMonth(dateRange: $dateRange) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfPartsBreakdownByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return numberOfPartsBreakdownByMonth;
  }

  /**
   * To call the get number of work order by month endpoint
   */
  async getNumberOfWorkOrderByMonth(startDate: string, endDate: string) {
    const query = gql`
      query ($dateRange: DateRange!) {
        numberOfWorkOrderByMonth(dateRange: $dateRange) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfWorkOrderByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      }
    });

    return numberOfWorkOrderByMonth;
  }

    /**
   * To call the get looker url endpoint
   */
    async getLookerUrl() {
      const query = gql`
      query GetSignedLookerUrl($userId: String!) {
        getSignedLookerUrl(userId: $userId) 
      }
      `;
      const lookerUrl = await getGraphQLClientInstance({ secured: true }).request(query, {
        "userId": "user1"
      });
      console.log(lookerUrl.getSignedLookerUrl, 'lookerUrl');
      return lookerUrl;
    }
}

export const statisticsService = new StatisticsService();
